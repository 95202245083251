import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024 880">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">
		<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M919.924255,437.936829 
	C929.042297,421.366547 935.023010,404.282410 935.727173,385.512695 
	C936.261780,371.261749 932.861572,358.149200 925.838196,345.842163 
	C924.059143,342.724731 922.555115,339.450287 920.420410,335.257446 
	C924.224243,336.388000 925.863159,338.403412 927.498901,340.362030 
	C955.559692,373.962280 959.457703,410.889923 942.497559,450.588531 
	C931.945862,475.286652 914.230225,494.351105 894.032471,511.378571 
	C861.482239,538.819641 824.293274,558.466431 785.656982,575.654236 
	C783.295105,576.704834 780.911804,577.904541 777.386292,577.755310 
	C785.313721,570.231750 791.372070,562.004272 798.078552,554.332336 
	C824.357056,524.270447 843.231079,490.118805 854.316895,451.746887 
	C859.884521,432.475311 862.408203,412.674713 858.278381,392.980408 
	C852.620972,366.001007 836.243896,348.027954 809.289429,340.288818 
	C800.659485,337.811035 791.691528,337.020630 783.117065,335.023346 
	C782.851013,333.294067 783.842407,332.844696 784.762756,332.557922 
	C813.455872,323.617828 842.348999,320.598541 870.656677,333.118896 
	C897.502991,344.992920 910.077026,372.364685 906.778748,402.080597 
	C902.796021,437.964203 885.817078,467.973267 864.014954,495.773804 
	C849.685730,514.045593 833.965088,530.997864 816.618774,546.437927 
	C815.801086,547.165710 814.944885,547.850403 814.515869,548.931458 
	C854.870544,517.042480 894.239990,484.230438 919.924255,437.936829 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M157.678329,481.270477 
	C142.991776,460.271820 132.012253,437.933136 127.422089,412.953308 
	C123.223442,390.104095 124.523392,368.067810 140.453796,349.196869 
	C143.359253,345.755096 146.438736,342.465942 150.304092,340.052490 
	C151.439758,339.343445 152.713196,338.625580 154.002579,339.612396 
	C155.095184,340.448700 154.635315,341.692017 154.584503,342.854156 
	C154.258209,350.315491 153.605362,357.793030 153.852036,365.238983 
	C154.038025,370.853302 155.464706,371.471436 160.916687,369.671600 
	C163.270172,368.894684 165.626312,367.880005 167.695038,366.533600 
	C173.294357,362.889404 180.014862,364.926422 185.573105,361.751556 
	C186.841217,364.963898 185.056290,366.439331 184.094330,368.009399 
	C172.144516,387.513641 170.216843,408.720245 173.877548,430.658508 
	C181.895981,478.711975 204.464569,519.675293 235.926071,556.178711 
	C242.054901,563.289795 247.786179,570.744385 254.826920,577.440918 
	C252.028229,578.423584 250.080338,577.139099 248.214478,576.309082 
	C204.038284,556.656555 161.656693,534.093323 126.158371,500.472900 
	C104.770973,480.216919 88.847565,456.631531 82.329803,427.478821 
	C75.091705,395.104187 83.376579,366.551910 104.098869,341.197235 
	C105.966347,338.912292 108.014519,336.754578 110.721863,335.313904 
	C112.151291,337.222809 110.593292,338.314178 110.006126,339.503632 
	C105.444946,348.743164 99.834312,357.561249 98.166183,367.978699 
	C94.469315,391.065765 99.353775,412.593140 109.949196,433.148651 
	C125.929016,464.150116 148.961380,489.399902 174.341644,512.780212 
	C185.220459,522.801697 196.322388,532.546997 208.614761,540.952454 
	C190.259598,522.362427 172.928925,502.934723 157.678329,481.270477 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M520.026123,335.660950 
	C517.561157,340.229126 513.620483,338.066254 510.450928,338.199188 
	C502.775269,338.521027 502.765625,338.283508 502.719025,330.612091 
	C502.710907,329.278961 502.596710,327.937103 502.709137,326.614410 
	C502.979218,323.437012 501.692871,322.082275 498.414185,322.138184 
	C485.069244,322.365723 487.534882,320.886414 487.351532,332.844666 
	C487.157684,345.485199 487.300659,345.636261 474.714508,347.480713 
	C455.506775,350.295532 436.224213,349.661743 416.913361,348.575684 
	C404.893341,347.899719 393.097107,346.080170 381.423737,343.303406 
	C378.356232,342.573761 375.321503,341.624939 372.364044,340.527863 
	C358.046417,335.216492 353.509766,328.527802 353.980804,313.378326 
	C354.088226,309.924255 354.569061,306.481812 354.851227,303.332581 
	C356.358734,302.514282 357.246674,303.061371 358.065033,303.610016 
	C372.950806,313.589294 390.067780,316.371490 407.277771,318.555115 
	C420.183685,320.192596 433.211273,319.992554 446.207703,319.882965 
	C453.741943,319.819427 453.742188,319.809143 453.686371,312.065948 
	C453.680359,311.232758 453.637695,310.398651 453.665161,309.566681 
	C453.738159,307.354004 452.771606,306.127045 450.483795,305.992828 
	C448.322662,305.866058 446.169098,305.580811 444.007202,305.501648 
	C440.440521,305.371002 438.160248,306.765656 438.419678,310.731873 
	C438.616669,313.743469 437.245453,314.979645 434.279083,314.838959 
	C416.462219,313.994019 398.731262,312.552795 381.544342,307.322388 
	C376.911407,305.912476 372.382507,304.235382 368.488159,301.222382 
	C362.638672,296.696686 362.612976,293.457184 368.588531,289.197662 
	C375.250977,284.448547 383.076294,282.533081 390.839630,280.673462 
	C408.103302,276.538177 425.645172,275.291321 443.371552,275.851898 
	C447.614105,275.986115 452.084503,277.031494 455.679596,272.573853 
	C457.387054,270.456726 461.427032,271.678253 464.371643,271.840485 
	C468.438171,272.064545 469.937653,270.706360 469.854462,266.465515 
	C469.640076,255.539474 469.815674,255.613861 480.704285,255.679581 
	C488.490540,255.726562 496.304413,256.619171 504.642303,254.964966 
	C503.466644,262.207764 504.091064,269.266449 504.398834,276.662201 
	C502.708435,273.416016 500.237335,273.005280 497.303070,273.071594 
	C490.140320,273.233551 482.970062,273.295166 475.807892,273.149048 
	C472.331512,273.078094 470.844940,274.402771 471.207275,277.838562 
	C471.259399,278.332916 471.213348,278.837708 471.211487,279.337646 
	C471.179779,287.986511 471.179749,287.986511 479.895386,287.989197 
	C487.302979,287.991486 487.254303,287.991882 487.315155,295.482666 
	C487.393585,305.139557 485.730652,304.728790 496.298950,304.630463 
	C503.750610,304.561127 504.042297,304.905884 504.082001,312.573395 
	C504.089783,314.073120 504.138763,315.573181 504.119049,317.072418 
	C504.087158,319.496918 505.115997,320.643433 507.675262,320.811798 
	C511.408508,321.057404 515.982178,319.096771 518.683411,322.155457 
	C521.840149,325.729980 519.993713,330.776337 520.026123,335.660950 
M422.360657,296.583008 
	C422.366486,298.081207 422.424957,299.581451 422.366333,301.077118 
	C422.267670,303.594452 423.402496,304.696625 425.940430,304.682617 
	C439.776611,304.606354 437.396851,306.040283 437.707245,293.661194 
	C437.788696,290.412811 436.442871,289.069824 433.260864,289.293030 
	C431.770020,289.397583 430.242432,289.479645 428.771759,289.278046 
	C423.754150,288.590393 421.537170,290.573883 422.360657,296.583008 
M463.441223,304.708710 
	C465.621826,304.228516 468.642181,305.990265 469.631287,302.340332 
	C472.309662,292.457031 469.586395,288.938202 459.555542,289.352966 
	C459.059845,289.373474 458.563660,289.382812 458.067657,289.395294 
	C455.653473,289.456085 454.782410,291.040588 455.113922,293.047180 
	C455.746460,296.875519 452.940613,301.175018 456.628326,304.530182 
	C457.008423,304.876068 457.910309,304.670105 458.570801,304.681549 
	C459.893768,304.704468 461.217163,304.700684 463.441223,304.708710 
M483.774261,320.699982 
	C484.496399,320.357391 485.699188,320.167450 485.864166,319.647949 
	C487.102936,315.746674 486.438843,311.681946 485.520203,307.951263 
	C484.704773,304.639771 480.979492,306.358826 478.563538,305.793549 
	C477.605530,305.569366 476.592163,305.579254 475.603271,305.489990 
	C472.787476,305.235687 471.240692,306.226685 471.267090,309.351379 
	C471.382294,322.990509 469.779144,320.467896 481.930176,320.755005 
	C482.260925,320.762817 482.592224,320.747681 483.774261,320.699982 
M469.579620,334.908783 
	C469.631927,331.911926 469.707520,328.915222 469.727783,325.918152 
	C469.739655,324.158325 469.429932,322.775330 467.241028,322.251129 
	C457.715485,319.970001 454.489594,322.529846 454.937134,332.245361 
	C454.975342,333.074554 455.013580,333.914337 455.175171,334.724609 
	C455.387268,335.787964 456.078735,336.690033 457.154907,336.686218 
	C461.212341,336.671783 465.406403,338.147064 469.579620,334.908783 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M353.995636,429.987762 
	C353.065216,435.573547 350.051331,440.196930 347.076935,444.821533 
	C338.291199,458.481567 327.673981,470.583862 316.123383,481.980621 
	C303.101685,494.828888 290.472931,508.075378 277.330780,521.498413 
	C279.952698,522.753235 282.232605,522.601624 284.472076,522.605835 
	C298.635345,522.632751 312.800690,522.787842 326.960663,522.580078 
	C331.547668,522.512817 332.941437,524.088806 332.018433,528.459045 
	C331.091309,532.848755 330.229034,537.280884 329.800385,541.738770 
	C329.406067,545.840149 327.313354,546.979004 323.538269,546.930176 
	C313.375824,546.798706 303.210449,546.891174 293.046234,546.895447 
	C275.717072,546.902649 258.387878,546.931458 241.058762,546.907593 
	C234.231949,546.898193 233.866089,546.539978 234.660736,539.966248 
	C235.218796,535.349487 235.997833,530.754517 236.832703,526.177979 
	C237.349976,523.342407 238.742996,520.964966 240.857437,518.877625 
	C259.587097,500.388519 278.272308,481.854218 296.909485,463.271881 
	C299.088257,461.099487 301.575897,459.036804 302.499420,455.961853 
	C300.794189,453.463104 298.424438,454.235474 296.400391,454.219513 
	C282.404388,454.109344 268.407013,454.025330 254.411331,454.119751 
	C250.388397,454.146851 248.518295,453.237946 249.524628,448.660583 
	C250.627747,443.643036 251.137619,438.485443 251.728485,433.368408 
	C252.083206,430.296600 253.897156,429.714264 256.559906,429.663544 
	C267.550446,429.454224 278.537781,429.079590 289.527985,428.844727 
	C308.679657,428.435394 327.832367,428.070068 346.985352,427.728058 
	C349.267120,427.687317 351.731689,427.238495 353.728760,429.716492 
	C354.000000,429.999390 353.995636,429.987762 353.995636,429.987762 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M509.075745,544.673950 
	C509.952881,538.016418 510.818146,531.762207 511.938904,525.554138 
	C512.383850,523.089172 513.710571,520.948120 515.558777,519.121826 
	C533.676086,501.219604 551.785706,483.309387 569.830139,465.333740 
	C572.993652,462.182251 575.843933,458.716309 578.898376,455.329651 
	C577.037842,453.523590 575.116699,454.204285 573.420532,454.192841 
	C558.764709,454.093750 544.107544,454.003693 529.452393,454.113342 
	C525.107971,454.145844 523.322021,452.995911 524.485046,448.279022 
	C525.594421,443.779663 526.223999,439.118652 526.582031,434.491364 
	C526.860107,430.897308 528.284241,429.663025 531.867554,429.625275 
	C544.185730,429.495575 556.500061,429.045044 568.817261,428.779999 
	C586.294006,428.403870 603.771240,428.047852 621.249573,427.757111 
	C628.514221,427.636230 630.041016,429.900726 626.638367,436.433441 
	C619.670471,449.810852 610.584961,461.742676 600.168579,472.578064 
	C585.743347,487.583496 570.990417,502.273621 556.411560,517.131836 
	C555.074402,518.494629 553.460510,519.733276 552.812317,521.729431 
	C554.866333,523.166077 556.925720,522.600647 558.842102,522.609497 
	C573.331604,522.676514 587.822998,522.778564 602.310303,522.593201 
	C606.763062,522.536133 607.835388,524.187622 606.932861,528.272339 
	C605.893982,532.974182 605.038086,537.733337 604.443115,542.510010 
	C604.080688,545.419373 602.883179,546.474304 599.962341,546.481689 
	C571.487244,546.553772 543.012451,546.737976 514.537354,546.842957 
	C512.617737,546.850037 510.444672,547.237427 509.075745,544.673950 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M711.413330,475.369324 
	C709.710022,496.529877 707.968811,517.224365 706.471191,537.936401 
	C706.208252,541.572449 704.576416,542.346497 701.418945,542.305603 
	C693.098999,542.197754 684.775574,542.192627 676.455872,542.311218 
	C672.629456,542.365784 668.941467,542.598999 669.128662,536.831299 
	C668.043335,537.369141 667.371277,537.531250 666.935120,537.943176 
	C653.771729,550.373535 634.100952,546.348633 622.931030,531.473694 
	C609.791504,513.975769 612.349792,482.619110 628.123230,467.374878 
	C629.664124,465.885742 631.436951,464.589966 633.253052,463.443756 
	C635.688049,461.906921 637.984619,461.573242 639.092773,465.097717 
	C639.519714,466.455750 640.342651,467.649231 641.810303,467.694000 
	C647.774963,467.876007 653.969116,467.592133 659.398560,465.625793 
	C666.626404,463.008148 666.818542,462.320312 672.017761,467.996368 
	C672.568909,468.598083 672.915649,469.387054 673.346924,470.072815 
	C674.093994,469.339630 674.491394,469.136993 674.509766,468.904449 
	C675.179138,460.413239 675.087891,460.683777 683.397278,463.333862 
	C687.991821,464.799164 693.027527,464.944702 697.616272,463.022858 
	C702.044189,461.168365 707.409851,458.794800 710.858521,461.657776 
	C714.415100,464.610260 711.974915,470.364410 711.413330,475.369324 
M659.505920,492.131805 
	C654.472839,493.731598 653.437500,497.815399 653.351440,502.335663 
	C653.279114,506.138000 654.176636,509.631714 657.960876,511.527191 
	C661.814575,513.457397 665.461243,512.836975 668.472473,509.898560 
	C672.076965,506.381226 672.525696,502.027161 670.607178,497.533966 
	C668.677734,493.015198 665.048767,491.243225 659.505920,492.131805 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M425.248352,536.069214 
	C425.645203,541.590271 423.615021,543.679016 418.265869,543.392090 
	C410.460693,542.973328 402.615662,543.162292 394.791199,543.297607 
	C391.171600,543.360229 388.024017,543.261292 388.587280,537.655945 
	C383.860443,540.597168 380.310120,544.089172 375.481445,545.051270 
	C362.885040,547.560974 352.417938,543.827576 344.429718,533.817017 
	C330.768860,516.697693 332.544006,486.717072 347.924225,471.157532 
	C352.419983,466.609375 357.265686,463.595337 363.936646,466.414520 
	C365.506287,467.077881 367.231079,467.136108 368.794006,466.306396 
	C377.477600,461.696411 384.790100,463.758392 391.128510,470.792084 
	C391.559540,471.270416 392.128693,471.624268 393.085571,472.404602 
	C394.241730,464.432831 400.256714,464.095032 406.017578,463.866089 
	C412.166168,463.621704 418.337036,464.004242 424.495911,463.928467 
	C428.738403,463.876282 430.592712,465.649261 430.262970,469.964081 
	C429.464691,480.409943 428.869171,490.871246 428.075043,501.317444 
	C427.205536,512.755005 426.204712,524.182617 425.248352,536.069214 
M373.882629,509.297119 
	C375.981140,514.030823 379.916016,514.614746 384.313019,513.682495 
	C388.479370,512.799072 390.290314,509.717224 390.629944,505.749023 
	C391.027863,501.099884 389.670197,497.099243 385.234222,494.906860 
	C380.847809,492.738953 376.013855,494.737946 374.066864,499.255432 
	C372.757721,502.292908 372.985748,505.414795 373.882629,509.297119 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M736.317871,535.312622 
	C740.330383,529.688232 739.669556,524.504211 737.308777,518.596069 
	C730.331421,501.134155 723.933777,483.440521 717.324463,465.831848 
	C716.809082,464.458771 716.004883,463.115051 716.800171,460.631287 
	C721.767151,460.631287 727.027161,460.629059 732.287170,460.631958 
	C737.618530,460.634857 742.955994,460.799500 748.279053,460.592468 
	C751.724731,460.458496 753.355774,461.856262 754.280334,465.115417 
	C756.671875,473.545990 759.347290,481.896027 762.097412,490.893463 
	C765.467407,481.940308 768.701904,473.629883 771.701599,465.235535 
	C772.869385,461.967865 774.611328,460.522064 778.272644,460.599915 
	C788.597351,460.819427 798.930115,460.646179 809.259705,460.660217 
	C810.560120,460.661987 811.944885,460.390686 813.093750,461.334839 
	C813.826904,462.995789 812.687805,464.231018 812.064270,465.525940 
	C794.943359,501.084137 777.747192,536.606323 760.723816,572.211060 
	C758.977234,575.864014 756.895813,577.443848 752.750488,577.335571 
	C742.427856,577.065857 732.093384,577.260986 721.763855,577.223389 
	C720.139038,577.217468 718.417114,577.597412 716.069641,575.869385 
	C722.705322,562.571228 729.423523,549.107666 736.317871,535.312622 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M500.237305,464.328491 
	C508.061340,465.832031 510.205414,473.601654 517.000122,476.907288 
	C522.538513,475.679962 521.742004,467.258331 527.062744,464.348785 
	C528.635010,466.690186 527.383301,468.115204 526.704712,469.525726 
	C510.105865,504.028870 493.464508,538.511475 476.875946,573.019531 
	C475.729187,575.405090 474.595276,577.271057 471.458832,577.239380 
	C460.133820,577.125122 448.806915,577.198425 437.480743,577.183228 
	C436.499390,577.181885 435.475189,577.169128 434.767395,576.237976 
	C434.177948,574.188416 435.574005,572.739380 436.355225,571.163513 
	C442.789795,558.183716 449.221985,545.201965 455.797546,532.293457 
	C457.189362,529.561218 457.305695,527.282410 456.161713,524.325745 
	C449.017426,505.861755 442.131714,487.297638 435.171661,468.762451 
	C434.710449,467.534241 434.090759,466.312866 434.522217,464.859802 
	C435.727173,463.287659 437.518646,463.764679 439.097656,463.753845 
	C447.591980,463.695526 456.090607,463.865601 464.579895,463.649200 
	C468.401001,463.551819 470.317749,464.975586 471.338470,468.646240 
	C473.559509,476.633606 476.111755,484.528839 478.786377,493.307709 
	C481.556854,485.912384 484.038055,479.297363 486.513275,472.680115 
	C490.007172,463.339478 490.005310,463.338776 500.237305,464.328491 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M477.341431,382.543640 
	C441.430908,387.273743 406.192505,387.559296 371.792847,375.914124 
	C355.364319,370.352692 348.932037,355.514313 355.471741,338.496948 
	C380.963837,353.941956 409.318176,356.064606 438.047211,355.931061 
	C466.623505,355.798248 494.912231,353.847351 520.243164,339.011932 
	C522.117371,339.797729 522.086426,341.217804 522.125916,342.520844 
	C522.226562,345.848724 522.277344,349.179108 522.284729,352.508636 
	C522.312805,365.063751 518.541260,370.614868 506.733582,375.132507 
	C497.375946,378.712830 487.668427,380.977325 477.341431,382.543640 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M370.782898,382.194336 
	C395.758240,390.876984 421.234650,392.687439 447.015472,391.854309 
	C472.613556,391.027161 497.807007,388.288208 520.373657,374.656342 
	C526.523132,385.359467 521.813049,402.954102 511.495117,408.510986 
	C498.030579,415.762512 483.217834,418.427795 468.329437,420.005127 
	C440.700745,422.932159 413.125153,422.075104 385.866943,416.179230 
	C377.383545,414.344299 369.130219,411.718323 361.820038,406.760712 
	C356.932251,403.445953 354.119293,399.284149 354.563660,392.983612 
	C354.981262,387.062012 354.653107,381.087860 354.653107,374.038177 
	C360.532806,377.024048 365.479645,379.536194 370.782898,382.194336 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M354.003662,430.003235 
	C354.222778,423.611420 352.861633,417.092255 355.065460,411.025391 
	C356.572235,410.556091 357.478729,410.989410 358.320984,411.545319 
	C374.613220,422.298676 393.249298,424.921967 412.027740,426.703491 
	C438.182770,429.184814 464.229279,428.460876 489.984894,422.696320 
	C498.950836,420.689636 507.666107,417.932739 515.783936,413.547638 
	C517.657166,412.535797 519.553711,411.333496 520.836975,413.911438 
	C522.269165,416.788513 523.266968,420.340698 521.036499,422.851746 
	C519.423584,424.667572 517.796448,422.719330 517.507812,420.573456 
	C517.280823,418.886536 517.332153,416.701233 514.795837,417.844452 
	C512.997375,418.655029 513.038086,420.492950 514.195251,422.117157 
	C515.526306,423.985382 515.676086,426.818909 519.366455,426.420227 
	C522.563599,426.074799 522.333496,429.042389 522.032043,431.137268 
	C521.125427,437.438873 517.241272,441.514465 511.793793,444.429962 
	C503.322296,448.963928 494.185364,451.414215 484.847534,453.239655 
	C464.638000,457.190521 444.193848,458.214630 423.701935,457.111572 
	C407.584076,456.243927 391.555573,454.241852 376.050354,449.316071 
	C372.225464,448.100952 368.589722,446.488525 365.007324,444.695496 
	C359.117279,441.747406 355.176575,437.383087 354.144165,430.319031 
	C353.995636,429.987762 354.000000,429.999390 354.003662,430.003235 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M654.958435,344.037628 
	C638.541687,348.782867 623.797913,355.910370 611.043152,366.802979 
	C603.490906,373.252594 597.224487,380.733429 593.095032,389.889832 
	C591.673523,393.041748 591.519470,392.972260 587.104309,392.373230 
	C589.380798,387.939117 591.185608,383.494324 593.762024,379.554047 
	C612.600464,350.742859 637.693115,329.956787 671.635864,321.777802 
	C706.835815,313.295868 738.548584,322.143738 767.135315,343.512054 
	C785.977783,357.596558 800.218506,375.737213 812.333496,395.683136 
	C812.743286,396.357758 812.990540,397.131134 813.613892,398.534973 
	C809.845093,397.893707 808.086304,395.377258 805.998779,393.558777 
	C792.195251,381.534241 777.790710,370.123871 762.828918,359.748230 
	C729.930969,336.934235 693.429504,333.905762 654.958435,344.037628 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M731.829712,611.894836 
	C722.521790,630.106445 732.204712,648.981262 751.711426,651.338684 
	C761.139221,652.478027 771.342468,646.948059 774.794983,638.341919 
	C766.639954,638.341919 758.707458,638.095276 750.799988,638.433899 
	C746.009644,638.639038 744.363892,637.098511 744.556152,632.235046 
	C744.876465,624.128235 744.650269,623.984314 750.803162,623.957031 
	C763.462097,623.900940 776.121582,623.896179 788.780579,623.939697 
	C795.131287,623.961487 795.647034,624.658875 794.575806,630.984497 
	C791.547791,648.864807 779.312012,661.565247 761.859253,664.943481 
	C744.976990,668.211365 727.355469,660.607178 718.426147,645.781738 
	C710.769409,633.069153 710.471558,619.713623 717.423096,606.762695 
	C724.554810,593.475891 736.019775,586.225464 751.333435,585.639954 
	C767.013550,585.040405 779.561218,590.833984 788.399902,604.017212 
	C789.480591,605.628967 790.795776,607.270508 790.177734,610.145325 
	C783.033325,609.556152 776.054077,612.274353 769.143372,605.716736 
	C757.885193,595.033936 741.764771,598.189575 731.829712,611.894836 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M253.568512,620.975586 
	C253.554184,610.832642 253.557571,601.185181 253.511978,591.537964 
	C253.500336,589.072876 253.417740,586.803833 256.952332,586.847046 
	C266.745331,586.966858 276.573090,586.309387 286.239655,588.611084 
	C306.337952,593.396851 316.206238,606.897827 315.281860,628.415039 
	C314.506439,646.465088 301.765259,660.301575 283.319336,663.237549 
	C274.716095,664.606812 266.064270,664.015076 257.427124,664.144714 
	C254.000229,664.196106 253.401474,662.384583 253.438904,659.368774 
	C253.595734,646.737671 253.543625,634.104004 253.568512,620.975586 
M295.353180,610.203735 
	C289.190674,603.317688 281.080627,601.499329 272.322906,601.293884 
	C269.601807,601.230042 268.365784,602.390381 268.374207,605.146362 
	C268.415344,618.642639 268.414886,632.139221 268.381104,645.635498 
	C268.374603,648.229797 269.227020,649.694702 272.106171,649.714722 
	C282.047211,649.783936 291.523743,648.031799 296.799500,638.686584 
	C301.967407,629.532410 301.680054,619.876648 295.353180,610.203735 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M342.578674,646.484253 
	C340.737549,651.173706 338.915771,655.474976 337.510834,659.908325 
	C336.473846,663.180603 334.539429,664.459106 331.233612,664.189575 
	C327.659119,663.898132 323.976959,664.694763 320.600464,663.463745 
	C319.761169,661.123657 320.991119,659.554077 321.664459,657.943542 
	C331.035675,635.529907 340.514313,613.160889 349.808228,590.715332 
	C351.150848,587.472839 353.207031,586.607727 356.380890,586.832153 
	C359.466248,587.050293 362.627960,586.286377 364.286316,590.293579 
	C374.020233,613.813904 383.912750,637.268616 393.729523,660.754700 
	C393.973907,661.339417 393.960175,662.031982 394.059662,662.631042 
	C385.960052,666.935791 378.524750,664.356323 375.626556,656.343689 
	C371.284637,644.339600 371.284607,644.339172 358.692963,644.348450 
	C355.030212,644.351135 351.367432,644.349609 347.704712,644.365051 
	C345.854614,644.372864 343.999054,644.398804 342.578674,646.484253 
M364.829041,628.490723 
	C362.324738,622.095764 359.820435,615.700745 356.869843,608.166077 
	C353.968292,616.044922 351.507385,622.727295 348.681396,630.401062 
	C354.877747,629.349121 360.018921,631.831970 364.829041,628.490723 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M689.910645,640.823914 
	C690.207336,638.098999 690.592346,635.786743 690.601685,633.473083 
	C690.656921,619.810059 690.726501,606.145447 690.557983,592.484253 
	C690.509399,588.546692 691.556641,586.797913 695.855957,586.791809 
	C705.887024,586.777466 705.976746,586.590820 705.903320,596.827087 
	C705.802917,610.817322 705.664307,624.809204 705.329773,638.795288 
	C704.968079,653.914734 695.148865,664.727783 681.541687,665.320374 
	C663.044312,666.125916 652.592285,658.322083 650.693726,641.595642 
	C648.772827,624.672852 650.226196,607.650024 649.951050,590.674377 
	C649.911804,588.255127 651.079895,586.934509 653.572937,586.926270 
	C653.905945,586.925171 654.239380,586.914429 654.571960,586.896667 
	C665.314087,586.323547 665.313904,586.323364 665.317505,597.019226 
	C665.321960,610.182556 665.188293,623.348145 665.395142,636.508301 
	C665.485840,642.276367 666.398376,648.079773 672.827759,650.527100 
	C680.916016,653.605835 687.725159,649.783081 689.910645,640.823914 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M442.135376,654.208984 
	C451.094330,632.830627 459.942657,611.823059 468.628845,590.748657 
	C469.851227,587.782898 471.616241,586.547058 474.677155,586.861511 
	C477.648926,587.166809 481.187378,585.738220 482.872589,589.788208 
	C492.779083,613.596497 502.715942,637.392151 512.624390,661.199646 
	C512.806824,661.637939 512.765686,662.169250 512.899536,663.202759 
	C508.273071,664.817078 503.523834,664.243835 498.844330,663.706726 
	C496.971405,663.491760 496.560028,661.463745 495.945435,659.938049 
	C494.329102,655.925598 492.669800,651.927063 491.193207,647.862854 
	C490.265228,645.308716 488.706207,644.249390 485.951263,644.305603 
	C479.295013,644.441589 472.630859,644.471497 465.976532,644.294678 
	C462.936279,644.213867 461.433777,645.506531 460.531342,648.240112 
	C459.228149,652.187500 457.574341,656.020142 456.303284,659.976685 
	C455.342987,662.965820 453.563080,664.367371 450.423767,664.141602 
	C446.809082,663.881653 443.117767,664.699097 439.393860,663.418274 
	C438.707947,659.972351 441.100037,657.471924 442.135376,654.208984 
M482.646301,625.567383 
	C480.546967,620.357910 478.870148,614.947144 475.480499,608.993835 
	C472.593353,616.677917 470.173340,623.118713 467.573608,630.037903 
	C473.304443,630.336914 478.336212,630.694092 483.191681,629.867493 
	C483.964844,628.339844 483.264832,627.315918 482.646301,625.567383 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M678.946899,355.710266 
	C706.673096,350.095276 731.172424,356.855591 753.404175,372.871704 
	C769.062134,384.151917 780.750061,399.049347 790.817505,415.316498 
	C791.165955,415.879486 791.463867,416.488403 791.677490,417.112823 
	C791.760925,417.356750 791.546326,417.702606 791.471985,417.984528 
	C790.764343,418.398712 790.154297,418.153564 789.674316,417.668396 
	C781.224243,409.126312 770.503357,403.414093 761.849060,395.073883 
	C742.351929,376.284363 718.813049,367.795410 691.858154,368.976990 
	C665.487793,370.132904 642.041199,378.585754 623.051636,397.604706 
	C619.252380,401.409790 616.315125,405.822296 613.860413,410.595764 
	C612.940247,412.385010 612.365906,414.953644 609.290222,413.902618 
	C611.065735,394.442383 648.135559,363.343140 678.946899,355.710266 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M569.833557,586.895874 
	C574.816833,587.031555 579.263062,587.587219 583.562195,588.985291 
	C593.284668,592.147034 598.974426,599.860535 599.026184,609.867737 
	C599.088135,621.843750 593.642456,630.021606 583.351440,633.221313 
	C578.720886,634.661133 573.936279,635.454895 569.090637,635.210815 
	C565.319641,635.020996 564.152100,636.585632 564.267517,640.200500 
	C564.469177,646.515686 564.154724,652.847229 564.347351,659.163025 
	C564.460754,662.881470 562.944580,664.061157 559.363647,664.140381 
	C549.676270,664.354797 549.671021,664.477356 549.665771,654.923340 
	C549.654480,634.293457 549.786438,613.662109 549.547729,593.034912 
	C549.491028,588.138489 551.188110,586.524536 555.884216,586.834473 
	C560.356628,587.129761 564.864075,586.894653 569.833557,586.895874 
M583.376465,607.471191 
	C583.151794,607.025269 582.985291,606.536682 582.693481,606.140137 
	C579.726013,602.106812 571.009338,599.277527 566.374084,601.533081 
	C562.311462,603.510010 564.934387,607.766174 564.405640,610.959045 
	C564.057007,613.064331 564.342163,615.275940 564.358154,617.439819 
	C564.368469,618.833008 564.637512,620.000977 566.175293,620.627258 
	C575.020691,624.229919 584.179749,617.731323 583.376465,607.471191 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M430.173126,601.275146 
	C425.661469,600.743896 424.188629,602.370789 424.235291,606.576965 
	C424.425507,623.725037 424.152710,640.878906 424.405518,658.025391 
	C424.477936,662.939270 422.736023,664.731689 418.053009,664.152710 
	C417.069305,664.031067 416.046631,664.045959 415.059937,664.157349 
	C410.501129,664.671997 408.903870,662.663940 408.949677,658.130737 
	C409.121185,641.148682 408.890778,624.162354 409.103271,607.181213 
	C409.160370,602.614624 407.723236,601.029114 403.101685,601.175720 
	C395.306580,601.423035 392.449738,596.823853 395.047211,589.142883 
	C395.743683,587.083435 397.285431,586.891724 398.992035,586.889587 
	C410.813873,586.874817 422.635773,586.897827 434.457550,586.876770 
	C437.094788,586.872131 438.552460,587.933716 438.412750,590.734680 
	C438.363068,591.731140 438.413025,592.732239 438.409729,593.731262 
	C438.384979,601.233643 438.384399,601.233643 430.173126,601.275146 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M626.999146,664.144043 
	C621.504517,664.131409 616.508118,664.046265 611.514587,664.130127 
	C608.222595,664.185486 606.692932,662.974060 606.707458,659.449341 
	C606.800659,636.804688 606.805176,614.159302 606.719177,591.514648 
	C606.705688,587.971069 608.274902,586.974548 611.542358,586.851929 
	C622.071411,586.456787 622.071899,586.369141 622.078125,596.926331 
	C622.087280,612.578064 622.234375,628.231812 622.007141,643.880188 
	C621.940125,648.497742 623.452820,650.151367 628.032349,649.784851 
	C632.168091,649.453979 636.355591,649.833191 640.513611,649.696655 
	C643.957092,649.583557 645.142761,651.114929 645.271301,654.465271 
	C645.641785,664.125183 645.747375,664.121216 635.990540,664.133911 
	C633.159912,664.137573 630.329224,664.140320 626.999146,664.144043 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M695.003479,385.891663 
	C723.654663,386.209198 749.694275,407.906219 760.453064,428.619934 
	C757.725586,428.380890 756.370789,426.291168 754.581360,424.990753 
	C745.582031,418.450653 737.250732,410.798859 727.034302,406.202423 
	C702.601440,395.209991 678.635620,395.979309 655.598450,410.367859 
	C650.569885,413.508575 646.655396,417.765656 643.752441,422.971680 
	C643.007690,424.307190 642.669983,426.280060 640.437378,426.177399 
	C638.604736,410.151459 669.516846,387.162628 695.003479,385.891663 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M742.938904,430.079926 
	C746.351013,433.916229 749.580200,437.450592 751.822388,442.223816 
	C749.288147,442.575531 748.311890,440.773987 746.944641,439.817505 
	C738.113525,433.639160 730.126587,426.137939 719.954102,422.047302 
	C702.527771,415.039734 685.225708,415.474976 668.225769,423.534821 
	C661.307983,426.814606 655.673523,431.612488 651.931152,438.436768 
	C651.398804,439.407501 650.960266,440.639343 649.357849,440.477539 
	C647.780029,438.800446 649.292114,437.365051 649.952393,436.061157 
	C663.997009,408.329498 696.694397,395.964264 727.396729,416.310913 
	C733.133667,420.112762 738.039246,424.817413 742.938904,430.079926 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M710.490356,438.400177 
	C691.490051,433.782562 675.236755,436.738647 663.234131,453.389496 
	C662.443542,445.086609 676.151672,432.249908 688.393127,429.001068 
	C708.849365,423.572052 730.621582,439.575317 738.408752,455.372589 
	C728.620483,449.465271 721.233459,441.447601 710.490356,438.400177 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M219.064087,339.003845 
	C219.950256,335.926605 222.015533,333.624542 222.275177,330.497559 
	C222.392838,329.080627 223.728333,328.405487 225.093521,328.014099 
	C229.758087,326.676819 245.906662,330.799255 250.869919,335.252075 
	C239.578125,335.534851 229.891907,340.598297 219.064087,339.003845 
z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
